<template>
  <div>

    <!-- Main Row -->
    <div class="row">

      <!-- Form -->
      <div class="col-md-12">
        <Card :title="'Edit Biaya Laboratorium'">
          <template v-slot:body>
            <Form
              :form="form"
              :route="'lab-procedures/' + form.id"
            />
          </template>
        </Card>
      </div>

    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import Form from '@/component/lab-procedures/Form.vue'
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Form
  },

  data() {
    return {
      // Form
      form: {
        name: '',
        price: '',
        description: '',
        lab_category_id: '',
        doctor_commission: '',
        hospital_income: '',
      },
    }
  },

  methods: {

    async get() {
      this.form = await module.get('lab-procedures/' + this.$route.params.id)
      // If Data Not Found
      if (this.form == null) {
        // Redirect To List
        this.$router.push('/lab-procedure/list')
      } else {
        this.form['_method'] = 'put'
        this.form.price = parseInt(this.form.price).toLocaleString('id-ID')
      }
    }

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Pemeriksaan Lab", route: "" },
      { title: "Daftar Biaya Laboratorium", route: "/lab-procedure/list" },
      { title: "Ubah" },
    ])
    // Get Data
    this.get()
  },

}

</script>